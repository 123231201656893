// Add ScrollMagic controller for onEnter (add script via cdn in template.php)
// var _dropsolid_base_scrollmagic_controller = new ScrollMagic.Controller({
//   globalSceneOptions: {
//     triggerHook: 'onEnter'
//   }
// });

(function ($) {
  Drupal.behaviors.dropsolid_base = {
    attach: function (context, settings) {

      // Set current breakpoint
      var currentBreakpoint = $.getBreakpoint();

      //Once needed to avoid multiple clicks when extra content is added to the page. (even vimeo vid)
      // $('.menu h2', context).once('h2').click(function () {
      //   $(this).parents('.menu').toggleClass('open');
      // });
      // $('.menu a.active', context).once('a.active').click(function (event) {
      //   event.preventDefault();
      //   $(this).parents('.menu').toggleClass('open');
      // });

      // Click away Drupal messages
      $('.messages .close').click(function (event) {
        event.preventDefault();
        $(this).parents('.messages').addClass('closing');
      });
      $('.messages').bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
        if ($(this).hasClass('closing')) {
          $(this).addClass('closed');
        }
      });

      // Scrollto
      $('a[href*=#]:not([href=#])').click(function (event) {
        var path = this.href;
        var Current = window.location.pathname;

        // if link is on current page, prevent reload.
        if (path.indexOf(Current) >= 0) {
          event.preventDefault();
        }

        // get ID/hash from url
        var id = event.target.href.substring(event.target.href.indexOf("#")+1);

        $.scrollto($('#' + id), 56);
      });

      // Scrollmagic example code
      // new ScrollMagic.Scene({triggerElement: 'body'})
      //   .on("enter", function (e) {
      //     $('header').addClass('scroll');
      //   })
      //   .on("leave", function (e) {
      //     $('header').removeClass('scroll');
      //   })
      //   .offset($(window).height() + 10)
      //   .addTo(_dropsolid_base_scrollmagic_controller);

      // Add responsive functionality to tables added via WYSIWYG
      $('table').each(function() {
        if(!$(this).hasClass('responsive')) {
          if($(this).find('thead').length) {
            var headings = [];
            $(this).find('th').each(function(){
              headings.push($(this).text());
            });
            var count = 0;
            $(this).find('tr').each(function(){
              $(this).find('td').attr('data-title', headings[count-1]);
              ++count;
            });
          } else {
            $(this).find('tr').each(function(){
              var heading = $(this).find('th').text();
              $(this).find('td').each(function(){
                $(this).attr('data-title', heading);
              });
            });
          }
          $(this).addClass('responsive');
        }
      });


      // Add toggle listener for responsive menu
      $('.main-menu .title').click(function(){
        $('.main-menu > ul li a').toggleClass("open");
      });

      $('.lang-bar .title').click(function(){
        $(this).toggleClass("rotate");
        $('.language-switcher-locale-url').slideToggle();
      })

      $('.sidebar .title').click(function(){
        $('.sidebar > ul').slideToggle();
      })

     // Image gallery thumb slider
      $(window).load(function() {
        $('.flexslider').flexslider({
          namespace: "flex-",             //{NEW} String: Prefix string attached to the class of every element generated by the plugin
          selector: ".slides > li",       //{NEW} Selector: Must match a simple pattern. '{container} > {slide}' -- Ignore pattern at your own peril
          animation: "fade",              //String: Select your animation type, "fade" or "slide"
          easing: "swing",               //{NEW} String: Determines the easing method used in jQuery transitions. jQuery easing plugin is supported!
          reverse: false,                 //{NEW} Boolean: Reverse the animation direction
          animationLoop: false,             //Boolean: Should the animation loop? If false, directionNav will received "disable" classes at either end
          smoothHeight: false,            //{NEW} Boolean: Allow height of the slider to animate smoothly in horizontal mode
          startAt: 0,                     //Integer: The slide that the slider should start on. Array notation (0 = first slide)
          slideshow: false,                //Boolean: Animate slider automatically
          animationSpeed: 600,            //Integer: Set the speed of animations, in milliseconds
          initDelay: 0,                   //{NEW} Integer: Set an initialization delay, in milliseconds
          randomize: false,
          controlNav: "thumbnails",
          directonNav: false
        });
      });
    }
  };
})(jQuery);
